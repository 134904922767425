import React, { useContext, useState, useEffect } from "react";
import { StoreContext } from "../context/store-context";

export function AddToCart({ variantId, quantity, available, ...props }) {
  const { addVariantToCart, loading } = useContext(StoreContext);
  const [addedToCart, setAddedToCart] = useState(false);

  function addToCart(e) {
    e.preventDefault();
    addVariantToCart(variantId, quantity);
    setAddedToCart(true);
  }

  useEffect(() => {
    if (addedToCart) {
      const resetAddedToCart = setTimeout(() => {
        setAddedToCart(false);
      }, 4000);
      return () => clearTimeout(resetAddedToCart);
    }
  }, [addedToCart]);

  return (
    <div className="product-buttons">
      <button
        type="submit"
        className="uppercase"
        onClick={addToCart}
        disabled={!available || loading}
        {...props}
      >
        {available ? (
          <>{addedToCart ? <>Added to Bag</> : <>Add to Bag</>}</>
        ) : (
          <>Out of Stock</>
        )}
      </button>
    </div>
  );
}

// import React from "react";

// export const Buttons = ({ context, available, productVariant, quantity }) => {
//   const handleAddToCart = () => {
//     context.addVariantToCart(productVariant.shopifyId, quantity);
//   };

//   return (
//     <div className="product-buttons">
//       <button
//         disabled={!available}
//         onClick={handleAddToCart}
//         className="uppercase"
//       >
// Add to Bag
// <span className="spacer">|</span>
// <span className="price">{productVariant.price}</span>
//       </button>
//     </div>
//   );
// };
