import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";

const OuterFormContainer = styled.div`
  position: relative;

  max-width: 480px;
  width: 100%;
  min-height: 185px;

  background: #f9f9f9;
  padding: 30px;

  cursor: auto;

  & .close-form-container {
    position: absolute;
    top: 0;
    right: 0;

    & button {
      display: block;

      padding: 7px 14px;
      margin: 0 0 0 auto;

      @media (max-width: 768px) {
        padding: 14px;
      }
    }
  }
`;

const FormContainer = styled.div`
  width: 100%;
  max-width: 420px;
  margin: 0 !important;
  padding: 0 !important;

  font-family: "Karla", "Helvetica Neue", "Lucida Grande", sans-serif !important;

  /* @media (max-width: 768px) {
    max-width: 300px;
  } */

  & .sign-up-text-container {
    &.top {
      margin: 0 0 30px 0;

      & p {
        margin: 0;
        line-height: 22px;

        @media (max-width: 768px) {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    &.bottom {
      margin: 30px 0 0 0;

      & p {
        font-size: 13px;
        line-height: 17px;
        letter-spacing: 0.02em;

        margin: 0;

        & a {
          text-decoration: underline;
          text-decoration-thickness: from-font;
          text-underline-offset: 2px;
        }

        @media (max-width: 768px) {
          font-size: 11px;
        }
      }
    }
  }

  & #sib-container {
    background-color: #f9f9f9;
  }

  & #sib-container,
  & .sib-form-block {
    padding: 0 !important;
  }

  & .entry__field {
    position: relative !important;
    margin: 0 !important;
    border: 0 !important;
    border-radius: 0 !important;
    background: transparent !important;

    &:focus-within {
      box-shadow: none !important;
    }
  }

  & .outer-wrapper {
    display: grid;
    grid-template-columns: 300px 120px;
    align-items: center;

    position: relative;

    @media (max-width: 600px) {
      display: block;
      grid-template-columns: unset;
    }
  }

  & .input-wrapper {
    width: 100%;

    &:last-of-type {
      border-bottom: 0;
    }
  }

  & button {
    font-family: "Karla", "Helvetica Neue", "Lucida Grande", sans-serif;
    font-weight: 500;

    display: inline-block;
    width: 120px;

    border: 1px solid #000;

    padding: 0 15px !important;

    font-size: 13px !important;
    line-height: 39px;
    cursor: pointer;
    height: 40px !important;

    text-transform: uppercase;
    letter-spacing: 0.02em;

    min-height: unset;

    color: #000;

    transition: 300ms all ease;

    &:hover {
      background-color: #000;
      color: #fff;
    }

    @media (max-width: 768px) {
      font-size: 11px !important;
      letter-spacing: 0.1em !important;
      /* max-width: 300px; */
      width: 100%;
    }
  }

  & input {
    border-top: 1px solid #000 !important;
    border-left: 1px solid #000 !important;
    border-bottom: 1px solid #000 !important;

    border-radius: 0 !important;
    padding: 0 15px !important;

    font-size: 15px !important;
    line-height: 39px;
    height: 38px !important;

    -webkit-appearance: none;
    width: 100% !important;

    font-family: "Karla", "Helvetica Neue", "Lucida Grande", sans-serif;

    @media (max-width: 768px) {
      font-size: 12px !important;
    }

    @media (max-width: 600px) {
      border: 1px solid #000 !important;
      border-bottom: 0 !important;
    }

    ::-webkit-input-placeholder {
      font-size: 12px;
      line-height: 29px;
      letter-spacing: 0.02em;

      text-transform: uppercase;
      color: #0d0d0d;
    }
    ::-moz-placeholder {
      font-size: 12px;
      line-height: 29px;
      letter-spacing: 0.02em;

      text-transform: uppercase;
      color: #0d0d0d;
    }
    :-ms-input-placeholder {
      font-size: 12px;
      line-height: 29px;
      letter-spacing: 0.02em;

      text-transform: uppercase;
      color: #0d0d0d;
    }
    :-moz-placeholder {
      font-size: 12px;
      line-height: 29px;
      letter-spacing: 0.02em;

      text-transform: uppercase;
      color: #0d0d0d;
    }
  }
`;

const Message = styled.div`
  margin: 30px auto;

  font-size: 16px;
  line-height: 22px;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }

  color: #0d0d0d;

  & p {
    margin: 0;
  }
`;

export const PopupSignupForm = ({ data, setIsPopupOpen }) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(false);

  const details = {
    email: email,
  };

  useEffect(() => {
    if (message === true) {
      const resetMailingList = setTimeout(() => {
        setMessage(false);
        setIsPopupOpen(false);
      }, 4000);
      return () => clearTimeout(resetMailingList);
    }
  }, [message]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await fetch("https://api.sendinblue.com/v3/contacts", {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json",
        "api-key":
          "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",
      },
      redirect: "follow", // manual, *folslow, error
      referrer: "client", // no-referrer, *client
      body: JSON.stringify(details), // body data type must match "Content-Type" header
    })
      .then((response) => {
        // reset form
        document.getElementById("sib-form").reset();
        // display success message
        setMessage(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <link
        rel="stylesheet"
        href="https://assets.sendinblue.com/component/form/2ef8d8058c0694a305b0.css"
      />
      <link
        rel="stylesheet"
        href="https://assets.sendinblue.com/component/clickable/b056d6397f4ba3108595.css"
      />
      <link
        rel="stylesheet"
        href="https://assets.sendinblue.com/component/progress-indicator/f86d65a4a9331c5e2851.css"
      />
      <link
        rel="stylesheet"
        href="https://sibforms.com/forms/end-form/build/sib-styles.css"
      />

      <OuterFormContainer>
        {message === true ? (
          <Message className="cardo">
            <PrismicRichText field={data.thank_you_text.richText} />
          </Message>
        ) : (
          <FormContainer className="sib-form">
            <div className="sign-up-text-container top cardo">
              <PrismicRichText field={data.signup_text.richText} />
            </div>

            <div id="sib-form-container" className="sib-form-container">
              <div
                id="sib-container"
                className="sib-container--large sib-container--vertical"
              >
                <form
                  id="sib-form"
                  method="POST"
                  data-type="subscription"
                  onSubmit={handleSubmit}
                >
                  <div className="outer-wrapper">
                    <div className="input-wrapper">
                      <div className="sib-input sib-form-block">
                        <div className="form__entry entry_block">
                          <div className="form__label-row ">
                            <div className="entry__field">
                              <input
                                className="input email-address"
                                maxLength="200"
                                type="email"
                                id="email"
                                name="email"
                                autoComplete="off"
                                placeholder="Email"
                                data-required="true"
                                required
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="signup-button">
                      <div className="sib-form-block">
                        <button
                          className="sib-form-block__button sib-form-block__button-with-loader"
                          form="sib-form"
                          type="submit"
                        >
                          <svg
                            className="icon clickable__icon progress-indicator__icon sib-hide-loader-icon"
                            viewBox="0 0 512 512"
                          >
                            <path d="M460.116 373.846l-20.823-12.022c-5.541-3.199-7.54-10.159-4.663-15.874 30.137-59.886 28.343-131.652-5.386-189.946-33.641-58.394-94.896-95.833-161.827-99.676C261.028 55.961 256 50.751 256 44.352V20.309c0-6.904 5.808-12.337 12.703-11.982 83.556 4.306 160.163 50.864 202.11 123.677 42.063 72.696 44.079 162.316 6.031 236.832-3.14 6.148-10.75 8.461-16.728 5.01z" />
                          </svg>
                          Subscribe
                        </button>
                      </div>
                    </div>

                    <input
                      type="text"
                      name="email_address_check"
                      value=""
                      className="input--hidden"
                    />
                    <input type="hidden" name="locale" value="en" />
                  </div>
                </form>
              </div>
            </div>

            <div className="sign-up-text-container bottom cardo">
              <PrismicRichText field={data.privacy_policy_text.richText} />
            </div>
          </FormContainer>
        )}
      </OuterFormContainer>
    </>
  );
};
