import React from "react";
import styled from "styled-components";
import { useSelect } from "downshift";

// Components
import { CurrencyDropdownArrow } from "../icons/arrows";

const DropDownContainer = styled.div`
  position: relative;

  width: 400px;

  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #0d0d0d;

  & label {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.02em;
  }

  & button[aria-expanded="true"] + ul {
    border: 1px solid #000;
    padding: 10px 0;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;
const DropDownHeader = styled.button`
  display: grid;
  grid-template-columns: auto 10px;
  grid-column-gap: 10px;
  align-items: center;

  min-width: 115px;

  color: #0d0d0d;
  text-align: left;

  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
`;

const DropDownList = styled.ol`
  position: absolute;
  top: 22px;
  left: 0;
  right: 0;

  z-index: 1;

  max-height: 280px;
  width: 100%;
  overflow-y: auto;

  background-color: #fff;

  &.open {
    border: 1px solid #0d0d0d;
  }

  list-style: none;

  margin: 0;
  padding: 0;
`;
const DropDownListItem = styled.li`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;

  padding: 5px 10px;

  background: ${(props) => (props.ishighlighted ? "#0d0d0d" : "#fff")};
  cursor: pointer;
  color: ${(props) => (props.ishighlighted ? "#fff" : "#0d0d0d")};

  text-align: left;

  @media (max-width: 600px) {
    font-size: 12px;
    line-height: 26px;
  }
`;

export const VariantSelectors = ({
  handleOptionChange,
  items,
  name,
  index,
  initialSelectedItem,
}) => {
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items,
    initialSelectedItem: initialSelectedItem.title,
    onSelectedItemChange: ({ selectedItem }) => {
      handleOptionChange(index, selectedItem);
    },
  });

  return (
    <DropDownContainer>
      <label className="uppercase">{name}</label>

      <DropDownHeader
        {...getToggleButtonProps()}
        type="button"
        aria-label={`Select a ${name}`}
        className="uppercase"
      >
        {selectedItem && selectedItem}
        <CurrencyDropdownArrow isOpen={isOpen} />
      </DropDownHeader>

      <DropDownList
        {...getMenuProps()}
        aria-label={`Select a ${name}`}
        className={isOpen ? `open` : `closed`}
      >
        {isOpen &&
          items.map((value, index) => (
            <DropDownListItem
              ishighlighted={highlightedIndex === index}
              key={`${name}-${value}-variant`}
              {...getItemProps({ value, index })}
            >
              {value}
            </DropDownListItem>
          ))}
      </DropDownList>
    </DropDownContainer>
  );
};
