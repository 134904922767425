import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useEffectOnce, useSessionStorage } from "react-use";

// Hooks
import { useMediaQuery } from "../hooks/useMediaQuery";

// Components
import { Header } from "./header";
import { Footer } from "./footer";
import { Popup } from "../popup/popup";

const Main = styled.main`
  flex: 1;
`;

export const App = ({ location, data, children }) => {
  // Popup
  const [isPopupOpen, setIsPopupOpen] = useSessionStorage("isPopupOpen", false);

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 769px)");

  useEffect(() => {
    if (isPopupOpen === false) {
      const popupTimer = setTimeout(() => {
        setIsPopupOpen(true);
      }, 10000);
      return () => clearTimeout(popupTimer);
    }
  }, []);

  return (
    <>
      <Header location={location} isDesktop={isDesktop} />
      <Main className={isDesktop ? `desktop` : `mobile`}>{children}</Main>
      <Footer location={location} isDesktop={isDesktop} />

      {isPopupOpen && (
        <Popup isPopupOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen} />
      )}
    </>
  );
};
