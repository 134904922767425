import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";
import { SignupForm } from "../forms/signup-form";

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  padding: 0 20px;

  line-height: 40px;

  & ol {
    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & li {
      margin: 0 20px 0 0;

      & a,
      & p {
        font-size: 12px;
        line-height: 40px;
        letter-spacing: 0.05em;

        display: block;
        margin: 0;
      }
    }
  }

  & button {
    font-size: 12px;
    line-height: 40px;
    letter-spacing: 0.05em;

    display: block;
  }

  @media (max-width: 768px) {
    display: block;
    flex-direction: unset;
    flex-wrap: unset;
    justify-content: unset;

    padding: 10px 0 40px 0;
    border-top: 1px solid #efefef;

    line-height: 20px;

    & ol {
      display: block;
      flex-direction: unset;
      flex-wrap: unset;
      justify-content: unset;

      padding: 0 10px;
    }

    & .button-container {
      padding: 0 10px;
    }
  }
`;

export const Footer = ({ location }) => {
  const [isMailingListOpen, setIsMailingListOpen] = useState(false);

  useEffect(() => {
    setIsMailingListOpen(false);
  }, [location]);

  const data = useStaticQuery(graphql`
    {
      prismicMenus {
        data {
          footer_menu_links {
            link {
              document {
                ... on PrismicPage {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicTextPage {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
          social_links {
            link {
              richText
            }
          }
          mailing_list_signup_text
        }
      }
    }
  `);

  const footerMenuLinks = data.prismicMenus.data.footer_menu_links
    .filter((link) => link.link.document !== null)
    .map((link, index) => (
      <li className="uppercase" key={`single_footer_link_${index}`}>
        <Link to={link.link.document.url}>
          {link.link.document.data.title.text}
        </Link>
      </li>
    ));

  const socialLinks = data.prismicMenus.data.social_links.map((link, index) => (
    <li className="uppercase" key={`single_footer_link_social_link_${index}`}>
      <PrismicRichText field={link.link.richText} />
    </li>
  ));

  return (
    <>
      <FooterWrapper>
        <ol>
          {footerMenuLinks}
          {socialLinks}
          <li className="uppercase">
            <a
              href="https://kieranstartup.co.uk/"
              target="_blank"
              rel="noopener"
            >
              Site Credits
            </a>
          </li>
        </ol>

        <div className="button-container">
          <button
            className="uppercase"
            onClick={() => setIsMailingListOpen(true)}
          >
            {data.prismicMenus.data.mailing_list_signup_text}
          </button>
        </div>
      </FooterWrapper>
      {isMailingListOpen && (
        <SignupForm
          isMailingListOpen={isMailingListOpen}
          setIsMailingListOpen={setIsMailingListOpen}
        />
      )}
    </>
  );
};
