import React from "react";

export const CloseIcon = () => (
  <svg
    width="7"
    height="7"
    viewBox="0 0 7 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.29987 6.99986L0 0.699986L0.699986 7.85463e-07L6.99986 6.29987L6.29987 6.99986Z"
      fill="#0d0d0d"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.000142209 6.29987L6.30001 0L7 0.699986L0.700128 6.99986L0.000142209 6.29987Z"
      fill="#0d0d0d"
    />
  </svg>
);
