import React, { useContext, useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import LazyLoad from "react-lazyload";

// Utils
import { getMetafield } from "../utils/get-metafield";
import { ProductPrice } from "./product-price";

const SingleProductContainer = styled.div`
  & .product-image {
  }

  & .product-title {
    margin: 10px 0 0 0;

    & h3,
    & p {
      font-size: 13px;
      line-height: 19px;
      letter-spacing: 0.02em;
      margin: 0;

      @media (max-width: 768px) {
        font-size: 11px;
        line-height: 15px;
      }
    }
  }
`;

export const SingleProduct = ({ product, index }) => {
  return (
    <SingleProductContainer className="single-product">
      <LazyLoad height={200}>
        <Link to={`/products/${product.handle}/`}>
          {product.featuredImage !== null && (
            <GatsbyImage
              image={product.featuredImage.gatsbyImageData}
              alt={product.title !== null ? product.title : ""}
              draggable="false"
            />
          )}
        </Link>
      </LazyLoad>

      <Link to={`/products/${product.handle}/`}>
        <div className="product-title">
          <h3 className="uppercase">{product.title}</h3>
          <p className="materials cardo-italic">
            {product.metafield !== null && product.metafield.value}
          </p>

          <p className="price">
            <ProductPrice
              price={product.priceRangeV2.minVariantPrice.amount}
              salePrice={product.variants[0].compare_at_price}
            />
          </p>
        </div>
      </Link>
    </SingleProductContainer>
  );
};
