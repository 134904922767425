import * as React from "react";
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews";

// Context
import { StoreProvider } from "./src/components/context/store-context";

// Prismic
import { linkResolver } from "./src/components/link-resolver/linkResolver";

// Pages
// import Cart from "./src/pages/cart";
// import Index from "./src/pages/index";

// Templates
import PageTemplate from "./src/templates/page";
import Product from "./src/templates/product";
import TextPage from "./src/templates/text-page";

// import Project from "./src/templates/project";

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
        linkResolver,
        componentResolver: componentResolverFromMap({
          page: PageTemplate,
          product: Product,
          text_page: TextPage,
        }),
      },
    ]}
  >
    <StoreProvider>{element}</StoreProvider>
  </PrismicPreviewProvider>
);
