import React, { useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { useAsyncFn } from "react-use";
import { useSessionStorage } from "react-use";

// Components
import { ProductPrice } from "./product-price";

const ProductDetailsContainer = styled.div`
  margin: 10px 0 0 0;

  & h3,
  & p {
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.02em;
    margin: 0;

    @media (max-width: 768px) {
      font-size: 11px;
      line-height: 15px;
    }
  }

  & .sale-price {
    margin-left: 10px;
  }
`;

export const ProductDetails = ({ product }) => {
  const [inStorageproductMaterial, setInStorageProductMaterial] =
    useSessionStorage(product.id, "");

  const [productMaterial, getProductMaterial] = useAsyncFn(async (id) => {
    const response = await fetch(`/.netlify/functions/get-product-material`, {
      method: "POST",
      body: JSON.stringify({
        id: id,
      }),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    });

    const result = await response.json();
    if (result.material.length >= 1) {
      setInStorageProductMaterial(result.material[0]);
    }
    return result;
  }, []);

  useEffect(() => {
    if (product !== null && inStorageproductMaterial === "") {
      getProductMaterial(product.admin_graphql_api_id);
    }
  }, [product]);

  if (product === null) return null;

  const price = product.variants
    .map((variant) => parseInt(variant.price))
    .sort();

  const salePrice = product.variants
    .map((variant) => variant.compare_at_price)
    .sort();

  return (
    <ProductDetailsContainer className="product-details-container">
      <h3 className="uppercase">{product.title}</h3>
      <p className="materials cardo-italic">
        {inStorageproductMaterial === "" ? (
          <>
            {productMaterial.loading === false && (
              <>
                {productMaterial.value !== undefined && (
                  <>
                    {productMaterial.value.material !== undefined &&
                      productMaterial.value.material !== null && (
                        <>
                          {productMaterial.value.material.length >= 1 &&
                            productMaterial.value.material[0]}
                        </>
                      )}
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <>{inStorageproductMaterial}</>
        )}
      </p>
      <p className="price">
        <ProductPrice price={price[0]} salePrice={salePrice[0]} />
      </p>
    </ProductDetailsContainer>
  );
};
