import React, { useState, useRef, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import styled from "styled-components";
import { useClickAway, useLockBodyScroll, useToggle } from "react-use";

// Icons
import { CloseIcon } from "../icons/close-icon";
import { useAsyncFn } from "react-use";

const CareGuideContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;

  z-index: 300;

  transition: 300ms transform ease;

  background-color: #fcfcfc;

  width: 500px;
  padding: 25px;

  @media (max-width: 600px) {
    width: 100%;
    padding: 10px;
  }

  &.visible {
    transform: translateX(0);
  }

  &.hidden {
    transform: translateX(500px);

    @media (max-width: 600px) {
      transform: translateX(100%);
    }
  }

  & .toggle-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;

    margin: 0 0 25px 0;

    & .close-care-guide-container {
      & button {
        padding: 5px;
        & svg {
          width: 10px;
          height: 10px;
        }
      }
    }
  }

  & .care-guide-title-container {
    & h1 {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.02em;
      text-transform: uppercase;

      @media (max-width: 600px) {
        font-size: 12px;
        line-height: 17px;
      }
    }
  }

  & .text-container {
    & p {
      font-size: 14px;
      line-height: 20px;

      letter-spacing: 0.02em;
    }

    @media (max-width: 600px) {
      & h1,
      & p,
      & a {
        font-size: 12px;
        line-height: 17px;
      }
    }
  }
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 200;
  transition: 300ms opacity ease;

  background-color: #7a7878;
  cursor: pointer;

  &.visible {
    opacity: 0.2;
    visibility: visible;
  }

  &.hidden {
    opacity: 0;
    visibility: hidden;
    user-select: none;
  }
`;

export const CareGuide = ({ isCareGuideOpen, setIsCareGuideOpen, id }) => {
  const [openCareGuide, setOpenCareGuide] = useState(`rings`);
  const [locked, toggleLocked] = useToggle(false);

  useLockBodyScroll(locked);

  useEffect(() => {
    toggleLocked(isCareGuideOpen);
  }, [isCareGuideOpen]);

  const ref = useRef(null);
  useClickAway(ref, () => {
    setIsCareGuideOpen(false);
    toggleLocked(false);
  });

  const [productCareGuide, getProductCareGuide] = useAsyncFn(async (id) => {
    const response = await fetch(`/.netlify/functions/get-care-guide`, {
      method: "POST",
      body: JSON.stringify({
        id: id,
      }),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    });

    const result = await response.json();
    return result;
  }, []);

  useEffect(() => {
    if (id !== null) {
      getProductCareGuide(id);
    }
  }, [id]);

  return (
    <>
      <CareGuideContainer
        className={isCareGuideOpen ? `visible` : `hidden`}
        ref={ref}
      >
        <div className="toggle-section">
          <div className="care-guide-title-container">
            {productCareGuide.loading === false &&
              productCareGuide.value !== undefined && (
                <h1>{productCareGuide.value.title}</h1>
              )}
          </div>

          <div className="close-care-guide-container">
            <button onClick={() => setIsCareGuideOpen(false)}>
              <CloseIcon />
            </button>
          </div>
        </div>

        {productCareGuide.loading === false &&
          productCareGuide.value !== undefined && (
            <div className="care-guide-download-container">
              <div className="text-container cardo">
                <p
                  dangerouslySetInnerHTML={{
                    __html: productCareGuide.value.body_html,
                  }}
                />
              </div>
            </div>
          )}
      </CareGuideContainer>

      <Background className={isCareGuideOpen ? `visible` : `hidden`} />
    </>
  );
};
