import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

// Components
// import { MobileCurrencyDropdown } from "./mobile-currency-dropdown";

// Icons
import { Logo } from "../icons/logo";
import { MenuIcon } from "../icons/menu-icon";

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  padding: 0 10px;
  z-index: 200;
  line-height: 27px;

  & nav {
    & .top-menu-navigation {
      display: grid;
      grid-template-columns: 30px 1fr 30px;

      position: relative;
      z-index: 10;
    }
  }

  & .logo-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    padding: 7px 0 6px 0;

    & a {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
  }

  & .alert {
    & p {
      font-size: 12px;
      letter-spacing: 0.05em;
      margin: 0;
    }
  }

  & ol {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &.right-menu {
      justify-content: flex-end;

      & .cart-link {
        position: relative;
      }
    }

    & li {
      margin: 0 15px 0 0;

      &:last-of-type {
        margin: 0;
      }

      & a,
      & button {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.05em;

        // transition: 250ms color ease;

        // &:hover,
        // &.current {
        //   color: #e9ccb2;
        // }
      }
    }
  }

  &.menu-open {
    background-color: #fff;

    & .top-menu-navigation {
      background-color: #fff;
    }

    & .mobile-menu-container {
      transform: translateY(0);
    }
  }

  & .mobile-menu-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;

    padding: 100px 10px 0 10px;

    transform: translateY(-100%);
    transition: 300ms transform linear;

    background-color: #fff;

    & .collections-menu {
      display: block;

      margin: 0 0 20px 0;

      & .single-dropdown-menu-container {
        display: block;
      }
    }

    & .current-converter-dropdown {
      border-top: 1px solid #efefef;
    }
  }
`;

const BagMarker = styled.span`
  position: absolute;
  top: 5px;
  right: -3px;

  width: 4px;
  height: 4px;

  opacity: ${(props) => props.opacity};
  transition: 250ms opacity ease;

  background: #000;
  display: block;
  border-radius: 100%;
`;

export const MobileMenu = ({
  location,
  quantity,
  activeCurrency,
  setActiveCurrency,
  data,
}) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(true);
  const [activeDropdownMenu, setActiveDropdownMenu] = useState(null);

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [location]);

  const menu = data.prismicMenus.data.body.map((content, index) => {
    if (content.slice_type === `page_link`) {
      return (
        <li
          key={`single_menu_item_${index}_${content.id}`}
          className="single-menu-link uppercase"
        >
          <Link to={content.primary.page_link.url}>
            {content.primary.page_title}
          </Link>
        </li>
      );
    }

    if (content.slice_type === `dropdown_menu`) {
      const links = content.items
        .filter((link) => link.collection.document !== null)
        .map((link, index) => (
          <li
            key={`single_link_${index}_${link.collection.document.id}`}
            className="uppercase"
          >
            <Link to={link.collection.document.url}>
              {link.collection.document.data.title.text}
            </Link>
          </li>
        ));

      return (
        <li
          key={`single_menu_item_${index}_${content.id}`}
          className="dropdown-menu"
        >
          <ol className="single-dropdown-menu-container">{links}</ol>
        </li>
      );
    }
  });

  return (
    <HeaderWrapper className={isMobileMenuOpen ? `menu-open` : `menu-closed`}>
      <nav>
        <div className="top-menu-navigation">
          <ol>
            {/* {menu.length >= 1 && ( */}
            <li>
              <button onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
                <MenuIcon />
              </button>
            </li>
            {/* )} */}
          </ol>

          <div className="logo-container">
            <Link to={`/`}>
              <Logo />
            </Link>
          </div>

          <ol className="right-menu">
            <li className="cart-link">
              <Link to={`/bag/`}>BAG</Link>
              <BagMarker opacity={quantity >= 1 ? 1 : 0} />
            </li>
          </ol>
        </div>

        <div className="mobile-menu-container">
          <div className="inner-mobile-menu-container">
            <ol className="collections-menu">{menu}</ol>

            {/* <div className="current-converter-dropdown">
              <MobileCurrencyDropdown
                activeCurrency={activeCurrency}
                setActiveCurrency={setActiveCurrency}
                location={location}
              />
            </div> */}
          </div>
        </div>
      </nav>
    </HeaderWrapper>
  );
};
