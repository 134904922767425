import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";
import Modal from "react-modal";
import { RemoveScroll } from "react-remove-scroll";
import { useKeyPressEvent } from "react-use";
import { GatsbyImage } from "gatsby-plugin-image";

// Slideshow
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

// Icons
// import { CloseIcon } from "../icons/slideshow-icons";

const LightboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  background-color: #ffffff;

  z-index: 100;
  height: 100%;
  width: 100%;

  & .container {
    height: calc(${(props) => props.height}px - 100px);
    width: 100%;

    & .lightbox-wrapper {
      position: relative;
      width: 100%;
      height: 100%;

      & .gatsby-image-wrapper {
        height: calc(${(props) => props.height}px - 100px);
      }
    }
  }

  & .single-media-container {
    padding: 0 140px;

    @media (max-width: 800px) {
      padding: 0 70px;
    }

    @media (max-width: 500px) {
      padding: 0 15px;
    }
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain !important;

    &.landscape {
    }

    &.portrait {
    }
  }
`;

const GalleryContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
  max-height: 100%;

  & > div,
  & .react-slideshow-container,
  & .react-slideshow-fade-wrapper,
  & .react-slideshow-fade-images-wrap,
  & .react-slideshow-fade-images-wrap > div,
  & .single-media-container {
    position: relative;

    width: 100%;
    height: 100%;
  }

  & img {
    object-fit: contain !important;
    width: 100%;
    height: 100%;
  }
`;

const CloseLightbox = styled.button`
  position: fixed;
  top: 0;
  right: 0;

  z-index: 200;

  font-size: 1;
  line-height: 1;
  letter-spacing: 0;

  padding: 16px 25px;

  & svg {
    & path {
      transition: 250ms fill ease;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      & svg {
        & path {
          fill: #dbaf96;
        }
      }
    }
  }

  @media (max-width: 800px) {
    padding: 15px;
  }
`;

const Arrow = styled.button`
  position: absolute;
  top: 20px;
  bottom: 20px;

  z-index: 100;

  &.previous {
    left: 20px;
  }

  &.next {
    right: 20px;
  }

  & svg {
    & path {
      transition: 250ms stroke ease;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      & svg {
        & path {
          stroke: #dbaf96;
        }
      }
    }
  }

  @media (hover: none) and (pointer: coarse) {
    display: none;
  }
`;

export const FullWidthGallery = ({ images, id }) => {
  // Slideshow Reference
  const slideRef = useRef();

  // // Keyboard navigation events for slideshow
  // const goBack = () => {
  //   if (slideRef.current !== undefined && media.length > 1) {
  //     slideRef.current.goBack();
  //   }
  // };

  // const goNext = () => {
  //   if (slideRef.current !== undefined && media.length > 1) {
  //     slideRef.current.goNext();
  //   }
  // };

  const content = images.map((image, index) => (
    <div
      className="slide image-container"
      key={`single_slide_image_${index}_${id}`}
    >
      <GatsbyImage
        image={image.image.gatsbyImageData}
        alt={image.image.alt !== null ? image.image.alt : ""}
        draggable="false"
      />
    </div>
  ));

  return (
    <GalleryContainer>
      {/* {media.length > 1 && (
        <Arrow
          aria-label="Previous Slide"
          className={`previous`}
          onClick={() => {
            slideRef.current.goBack();
          }}
        >

          Prev
        </Arrow>
      )} */}
      <Slide
        ref={slideRef}
        arrows={false}
        transitionDuration={1000}
        pauseOnHover={false}
        autoplay={true}
        infinite={true}
        canSwipe={content.length > 1 ? true : false}
        defaultIndex={0}
        indicators={true}
      >
        {content}
      </Slide>
      {/* {media.length > 1 && (
        <Arrow
          aria-label="Next Slide"
          className={`next`}
          onClick={() => {
            slideRef.current.goNext();
          }}
        >
          Next
        </Arrow>
      )} */}
    </GalleryContainer>
  );
};
