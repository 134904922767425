import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";
import Modal from "react-modal";
import { RemoveScroll } from "react-remove-scroll";
import { useKeyPressEvent } from "react-use";
import { GatsbyImage } from "gatsby-plugin-image";

// Slideshow
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const GalleryContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
  max-height: 100%;

  & > div,
  & .react-slideshow-container,
  & .react-slideshow-fade-wrapper,
  & .react-slideshow-fade-images-wrap,
  & .react-slideshow-fade-images-wrap > div,
  & .single-media-container {
    position: relative;

    width: 100%;
    height: 100%;
  }

  & img {
    object-fit: contain !important;
    width: 100%;
    height: 100%;
  }

  & .react-slideshow-container + ul.indicators {
    margin-top: 10px;
  }

  & .single-media-container {
    & .gatsby-image-wrapper {
      & img {
        object-fit: cover !important;
      }
    }
  }
`;

export const Gallery = ({ media }) => {
  const galleryMedia = media.map((media, index) => {
    if (media.mediaContentType === "IMAGE") {
      return (
        <div
          key={`single_product_image_container_${index}`}
          className={`single-image-container single-media-container`}
        >
          <GatsbyImage
            image={media.mobileImage.gatsbyImageData}
            alt={media.alt !== null ? media.alt : ""}
            draggable="false"
            aspectRatio={3 / 2}
          />
        </div>
      );
    }

    if (media.mediaContentType === "VIDEO") {
      const sources = media.sources.map((source, index) => (
        <source
          src={source.url}
          type={`video/${source.format}`}
          key={`single_video_${index}`}
        />
      ));
      return (
        <div
          key={`single_product_video_container_${index}`}
          className={`single-video-container single-media-container`}
        >
          <video autoPlay muted loop playsInline>
            {sources}
          </video>
        </div>
      );
    }
  });

  return (
    <GalleryContainer>
      <Slide
        arrows={false}
        transitionDuration={700}
        pauseOnHover={false}
        autoplay={false}
        infinite={true}
        canSwipe={media.length > 1 ? true : false}
        defaultIndex={0}
        indicators={true}
        easing={"ease"}
      >
        {galleryMedia}
      </Slide>
    </GalleryContainer>
  );
};
