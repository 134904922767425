import React, { useState } from "react";
import styled from "styled-components";
import { useEffectOnce } from "react-use";

// Components
import { SingleProduct } from "./single-product";

// Utils
import { shuffle } from "../utils/shuffle-array";

const Container = styled.div`
  padding: 0 20px;

  & .recommended-title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    margin: 100px 0 20px 0;

    & h2 {
      font-size: 15px;
      line-height: 19px;
    }

    @media (max-width: 768px) {
      margin: 0 0 15px 0;

      & h2 {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.02em;
      }
    }
  }

  & .related-products-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 100px;

    @media (max-width: 768px) {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 10px;
      grid-row-gap: unset;

      padding: 0;

      & .single-product {
        &:nth-of-type(3),
        &:nth-of-type(4) {
          display: none;
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: 60px 10px 0 10px;
  }
`;

export const RelatedProducts = ({ products }) => {
  const [shuffledArtworks, setShuffledArtworks] = useState(products);
  const [runOnce, setRunOnce] = useState(false);

  useEffectOnce(() => {
    setShuffledArtworks(
      shuffle(products).filter((product, index) => {
        if (index <= 3) {
          return product;
        }
      })
    );
    setRunOnce(true);
  });

  const relatedArtworks = shuffledArtworks.map((product, index) => (
    <SingleProduct
      product={product}
      index={index}
      key={`single_product_${index}_${product.id}`}
    />
  ));

  if (products.length === 0) return null;

  return (
    <Container className="content recommended-container">
      <div className="recommended-title">
        <h2 className="uppercase">More From The Collection</h2>
      </div>

      {runOnce && (
        <div className="related-products-container">{relatedArtworks}</div>
      )}
    </Container>
  );
};
