import React, { useContext } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";

// Context
import { StoreContext } from "../context/store-context";

// Components
import { DesktopMenu } from "../navigation/desktop-menu";
import { MobileMenu } from "../navigation/mobile-menu";

export const Header = ({ location, isDesktop }) => {
  const { checkout, loading, didJustAddToCart } = useContext(StoreContext);
  const items = checkout ? checkout.lineItems : [];
  const quantity = items.reduce((total, item) => {
    return total + item.quantity;
  }, 0);

  const data = useStaticQuery(graphql`
    {
      prismicMenus {
        data {
          body {
            ... on PrismicMenusDataBodyDropdownMenu {
              id
              slice_type
              primary {
                dropdown_menu_title
              }
              items {
                collection {
                  document {
                    ... on PrismicPage {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on PrismicMenusDataBodyPageLink {
              id
              slice_type
              primary {
                page_title
                page_link {
                  url
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <>
      {isDesktop ? (
        <DesktopMenu quantity={quantity} location={location} data={data} />
      ) : (
        <MobileMenu quantity={quantity} location={location} data={data} />
      )}
    </>
  );
};
