import React, { useRef } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import Modal from "react-modal";
import { GatsbyImage } from "gatsby-plugin-image";
import { useClickAway } from "react-use";

// Components
import { PopupSignupForm } from "../forms/popup-signup-form";

// Icons
import { CloseIcon } from "../icons/close-icon";

const PopupContainer = styled.div`
  max-width: 500px;

  & .image-container {
    position: relative;

    & img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    & .close-popup {
      position: absolute;
      top: 0;
      right: 10px;
    }
  }

  @media (max-width: 600px) {
    max-width: 80%;
  }

  @media (max-height: 800px) {
    /* max-width: 80%; */

    & .aspect-ratio-container {
      &::before {
        padding-top: 50%;
      }
    }
  }
`;

export const Popup = ({ isPopupOpen, setIsPopupOpen }) => {
  const ref = useRef(null);
  useClickAway(ref, () => {
    setIsPopupOpen(false);
  });

  const data = useStaticQuery(graphql`
    {
      prismicNewsletterPopup {
        data {
          is_popup_active
          image {
            alt
            gatsbyImageData(
              aspectRatio: 1.5
              srcSetMaxWidth: 1000
              layout: FULL_WIDTH
            )
          }
          signup_text {
            richText
          }
          thank_you_text {
            richText
          }
          privacy_policy_text {
            richText
          }
        }
      }
    }
  `);

  if (data.prismicNewsletterPopup.data.is_popup_active) {
    return (
      <Modal
        isOpen={isPopupOpen}
        portalClassName={`ReactModalPortal-mailchimp`}
        contentLabel={`Mailchimp Signup Form`}
        shouldFocusAfterRender={true}
      >
        <PopupContainer ref={ref}>
          <div className="image-container">
            {data.prismicNewsletterPopup.data.image !== null && (
              <GatsbyImage
                image={data.prismicNewsletterPopup.data.image.gatsbyImageData}
                alt={data.prismicNewsletterPopup.data.image.alt}
                draggable="false"
              />
            )}

            <button
              className="close-popup"
              onClick={() => setIsPopupOpen(false)}
            >
              <CloseIcon />
            </button>
          </div>
          <div className="email-container">
            <PopupSignupForm
              data={data.prismicNewsletterPopup.data}
              isPopupOpen={isPopupOpen}
              setIsPopupOpen={setIsPopupOpen}
            />
          </div>
        </PopupContainer>
      </Modal>
    );
  } else {
    return null;
  }
};
