import React, { useContext, useEffect, useState, useCallback } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

// Context
import { StoreContext } from "../components/context/store-context";
import { PageSetup } from "../components/context/page-setup";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

// Seo
import { PageSeo } from "../components/seo/page-seo";

// Icons
import { CareGuideIcon, SizeGuideIcon } from "../components/icons/arrows";

// Components
import { VariantSelectors } from "../components/product/variant-selectors";
import { AddToCart } from "../components/product/buttons";
import { SizeGuide } from "../components/product/size-guide";
import { CareGuide } from "../components/product/care-guide";
import { RelatedProducts } from "../components/product/related-products";
import { Gallery } from "../components/images/gallery";
import { ProductPrice } from "../components/product/product-price";

// Utils
import { getMetafield } from "../components/utils/get-metafield";
import isEqual from "lodash.isequal";

const Page = styled.div`
  margin: 75px 0;

  @media (max-width: 768px) {
    margin: 30px 0;
  }

  & .product-container,
  & .remaining-product-images-container {
    padding: 0 20px;
  }

  @media (max-width: 768px) {
    & .product-container {
      padding: 0;
    }

    & .remaining-product-images-container {
      padding: 0 10px;
    }
  }

  & .remaining-product-images-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    grid-auto-flow: dense;

    & .single-media-container {
      &.portrait {
        grid-column: span 1;
      }

      &.landscape {
        grid-column: span 2;
      }
    }
  }

  & .grid-12 {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 20px;

    @media (max-width: 768px) {
      display: block;
      grid-template-columns: unset;
      grid-column-gap: unset;
    }

    & .left-column {
      grid-column: 1 / 7;

      & .single-media-container {
        margin: 0 0 25px 0;

        // cursor: pointer;
      }

      @media (max-width: 768px) {
        grid-column: unset;
        margin: 0 0 25px 0;

        & .single-media-container {
          margin: 0;
        }
      }
    }

    & .right-column {
      grid-column: 7 / 13;
      margin: 0 0 20px 0;

      @media (max-width: 768px) {
        grid-column: unset;
      }

      & .text-container {
        position: sticky;
        top: 125px;
        max-width: 450px;
        margin: 0 auto;

        @media (max-width: 768px) {
          position: relative;
          top: unset;
          max-width: unset;

          padding: 0 10px;
        }

        & .collection-title {
          margin: 0 0 20px 0;

          & p {
            font-size: 9px;
            line-height: 11px;
            letter-spacing: 0.02em;

            color: #bcc5c5;
            margin: 0;
          }

          @media (max-width: 768px) {
            margin: 0 0 10px 0;
          }
        }

        & .product-title {
          margin: 0 0 35px 0;

          & h1 {
            font-size: 22px;
            line-height: 26px;
            letter-spacing: 0.03em;
          }

          @media (max-width: 768px) {
            margin: 0;

            & h1 {
              font-size: 18px;
              line-height: 21px;
            }
          }
        }

        & .product-description {
          & p {
            font-size: 14px;
            line-height: 20px;

            letter-spacing: 0.02em;

            // @media (max-width: 768px) {
            //   font-size: 12px;
            //   line-height: 17px;
            // }
          }
        }

        & .product-metafields-container {
          margin: 35px 0;

          & .metafield {
            margin: 15px 0;

            & h3 {
              font-size: 12px;
              line-height: 14px;

              letter-spacing: 0.02em;
            }

            & p {
              font-size: 14px;
              line-height: 20px;

              letter-spacing: 0.02em;
              margin: 0;
            }
          }

          // @media (max-width: 768px) {
          //   margin: 15px 0 0 0;
          // }
        }

        & .product-options-container {
          margin: 35px 0;

          & .variant-options-container {
            // display: grid;
            // grid-template-columns: 60px 120px;
            // align-items: baseline;
          }
        }

        & .price-container {
          margin: 35px 0;

          & p {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.02em;
            font-weight: 500;

            margin: 0;

            // @media (max-width: 768px) {
            //   font-size: 13px;
            // }
          }
        }

        & .product-information {
          margin: 25px 0 0 0;

          & p {
            font-size: 12px;
            line-height: 20px;

            margin: 0;
          }
        }

        & .product-buttons {
          max-width: 320px;
          margin: 0 0 30px 0;

          & button {
            font-size: 13px;
            line-height: 39px;
            letter-spacing: 0.05em;
            text-align: center;
            font-weight: 500;

            background-color: #000;
            color: #fff;

            width: 100%;
            height: 40px;

            transition: 250ms color ease, 250ms background-color ease;

            @media (max-width: 768px) {
              font-size: 11px;
              letter-spacing: 0.1em;
            }
          }

          @media (max-width: 500px) {
            max-width: 100%;
          }
        }

        & .product-links {
          & button {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.02em;

            &:first-of-type {
              margin: 0 30px 0 0;
            }
          }
        }
      }
    }
  }
`;

const Product = ({ data }) => {
  const [pageSetup, setPageSetup] = useContext(PageSetup);

  useEffect(() => {
    setPageSetup({
      pageColor: `#fff`,
      pageType: `product`,
    });
  }, []);

  const product = data.shopifyProduct;

  const { options, variants } = product;

  const { client } = useContext(StoreContext);

  const findFirstAvailableVariant = variants.find(
    ({ availableForSale }) => availableForSale === true
  );

  const initialVariant =
    findFirstAvailableVariant !== undefined
      ? findFirstAvailableVariant
      : variants[0];

  const [variant, setVariant] = useState({ ...initialVariant });
  const [quantity, setQuantity] = useState(1);

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant;

  const [available, setAvailable] = useState(productVariant.availableForSale);

  const checkAvailablity = useCallback(
    (productId) => {
      client.product.fetch(productId).then((fetchedProduct) => {
        const result =
          fetchedProduct?.variants.filter(
            (variant) => variant.id === productVariant.shopifyId
          ) ?? [];

        if (result.length > 0) {
          setAvailable(result[0].available);
        }
      });
    },
    [productVariant.shopifyId, client.product]
  );

  const handleOptionChange = (index, value) => {
    if (value === "") {
      return;
    }

    const currentOptions = [...variant.selectedOptions];

    currentOptions[index] = {
      ...currentOptions[index],
      value,
    };

    const selectedVariant = variants.find((variant) => {
      return isEqual(currentOptions, variant.selectedOptions);
    });

    setVariant({ ...selectedVariant });
  };

  useEffect(() => {
    checkAvailablity(product.storefrontId);
  }, [productVariant.storefrontId, checkAvailablity, product.storefrontId]);

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 768px)");

  // Size Guide
  const [isSizeGuideOpen, setIsSizeGuideOpen] = useState(false);

  // Care Guide
  const [isCareGuideOpen, setIsCareGuideOpen] = useState(false);

  const media = data.shopifyProduct.media.map((media, index) => {
    if (media.mediaContentType === "IMAGE") {
      return (
        <div
          key={`single_product_image_container_${index}`}
          className={`single-image-container single-media-container ${
            media.image.width > media.image.height ? `landscape` : `portrait`
          }`}
        >
          <GatsbyImage
            image={media.image.gatsbyImageData}
            alt={media.alt !== null ? media.alt : ""}
            draggable="false"
          />
        </div>
      );
    }

    if (media.mediaContentType === "VIDEO") {
      const sources = media.sources.map((source, index) => (
        <source
          src={source.url}
          type={`video/${source.format}`}
          key={`single_video_${index}`}
        />
      ));
      return (
        <div
          key={`single_product_video_container_${index}`}
          className={`single-video-container single-media-container landscape`}
        >
          <video autoPlay muted loop playsInline>
            {sources}
          </video>
        </div>
      );
    }
  });

  const materialsText = getMetafield(data.shopifyProduct, "materials");
  const measurementsText = getMetafield(data.shopifyProduct, "measurements");
  const careGuideId = getMetafield(data.shopifyProduct, "care_guide");

  const hasVariants = variants.length > 1;

  const firstMediaItem = media.filter((item, index) => index === 0);
  const remainingMediaItems = media.filter((item, index) => index >= 1);

  return (
    <>
      <PageSeo
        seoTitle={`${data.shopifyProduct.title} | SHANNON BOND`}
        seoText={data.shopifyProduct.description}
        seoImage={
          data.shopifyProduct.featuredImage !== null
            ? data.shopifyProduct.featuredImage.originalSrc
            : null
        }
      />

      <Page>
        <section className="product-container">
          <div className="grid-12">
            <div className="left-column">
              {isDesktop ? (
                <>{firstMediaItem}</>
              ) : (
                <Gallery media={data.shopifyProduct.media} />
              )}
            </div>

            <div className="right-column">
              <div className="text-container">
                <div className="inner-text-container">
                  <div className="collection-title">
                    <p className="uppercase">
                      {data.shopifyProduct.collections.length >= 1 && (
                        <Link
                          to={`/collections/${data.shopifyProduct.collections[0].handle}/`}
                        >
                          {data.shopifyProduct.collections[0].title}
                        </Link>
                      )}
                    </p>
                  </div>

                  <div className="product-title">
                    <h1 className="uppercase">{data.shopifyProduct.title}</h1>
                  </div>

                  <div
                    className="product-description cardo"
                    dangerouslySetInnerHTML={{
                      __html: data.shopifyProduct.descriptionHtml,
                    }}
                  />

                  {(materialsText !== null || measurementsText !== null) && (
                    <div className="product-metafields-container">
                      {materialsText !== null && (
                        <div className="metafield">
                          <h3 className="uppercase">Materials</h3>
                          <p className="cardo-italic">{materialsText}</p>
                        </div>
                      )}

                      {measurementsText !== null && (
                        <div className="metafield">
                          <h3 className="uppercase">Measurements</h3>
                          <p className="cardo-italic">{measurementsText}</p>
                        </div>
                      )}
                    </div>
                  )}

                  <div className="product-options-container">
                    {hasVariants &&
                      options.map(({ id, name, values }, index) => (
                        <div key={id}>
                          <VariantSelectors
                            handleOptionChange={handleOptionChange}
                            items={values}
                            name={name}
                            index={index}
                            initialSelectedItem={variant}
                          />
                        </div>
                      ))}
                  </div>

                  <div className="price-container">
                    <p>
                      <ProductPrice
                        price={variant.price}
                        salePrice={variant.compare_at_price}
                      />
                    </p>
                  </div>

                  <AddToCart
                    variantId={productVariant.storefrontId}
                    quantity={quantity}
                    available={available}
                  />

                  <div className="product-links">
                    {(product.productType !== `Earrings` ||
                      product.productType !== `Brooch`) && (
                      <button
                        onClick={() => setIsSizeGuideOpen(!isSizeGuideOpen)}
                      >
                        Size Guide <SizeGuideIcon />
                      </button>
                    )}
                    <button
                      onClick={() => setIsCareGuideOpen(!isCareGuideOpen)}
                    >
                      Care Guide <CareGuideIcon />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {isDesktop && (
          <section className="remaining-product-images-container">
            {remainingMediaItems}
          </section>
        )}

        <RelatedProducts
          products={data.allShopifyProduct.nodes}
          product={data.shopifyProduct}
        />
      </Page>

      <SizeGuide
        isSizeGuideOpen={isSizeGuideOpen}
        setIsSizeGuideOpen={setIsSizeGuideOpen}
      />
      <CareGuide
        isCareGuideOpen={isCareGuideOpen}
        setIsCareGuideOpen={setIsCareGuideOpen}
        id={careGuideId}
      />
    </>
  );
};

export default Product;

export const query = graphql`
  query ProductQuery($id: String, $collection: String) {
    shopifyProduct(id: { eq: $id }) {
      title
      descriptionHtml
      description
      productType
      tags
      handle
      shopifyId
      featuredImage {
        originalSrc
      }
      collections {
        title
        handle
      }
      seo {
        description
        title
      }
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      storefrontId
      variants {
        id
        title
        price
        availableForSale
        shopifyId
        storefrontId
        inventoryQuantity
        compare_at_price
        selectedOptions {
          name
          value
        }
      }
      metafields {
        value
        key
      }
      options {
        name
        values
        shopifyId
      }
      media {
        alt
        ... on ShopifyVideo {
          id
          sources {
            url
            width
            height
            format
          }
          mediaContentType
        }
        ... on ShopifyMediaImage {
          id
          image {
            width
            height
            originalSrc
            gatsbyImageData(layout: FULL_WIDTH)
          }
          mobileImage: image {
            width
            height
            gatsbyImageData(
              layout: FULL_WIDTH
              aspectRatio: 0.666
              placeholder: NONE
              backgroundColor: "#efefef"
            )
          }
          mediaContentType
        }
        ... on ShopifyExternalVideo {
          id
          embeddedUrl
          mediaContentType
        }
      }
    }
    allShopifyProduct(
      filter: {
        status: { eq: ACTIVE }
        id: { ne: $id }
        collections: { elemMatch: { id: { eq: $collection } } }
      }
    ) {
      nodes {
        title
        descriptionHtml
        description
        productType
        tags
        handle
        shopifyId
        featuredImage {
          gatsbyImageData(aspectRatio: 0.75)
        }
        collections {
          title
          handle
        }
        priceRangeV2 {
          maxVariantPrice {
            amount
            currencyCode
          }
          minVariantPrice {
            amount
            currencyCode
          }
        }
        storefrontId
        variants {
          id
          title
          price
          availableForSale
          shopifyId
          storefrontId
          inventoryQuantity
          compare_at_price
          selectedOptions {
            name
            value
          }
        }
        metafield(key: "materials", namespace: "custom") {
          value
        }
        options {
          name
          values
          shopifyId
        }
      }
    }
  }
`;
