import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
body {
  font-family: "Karla", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: normal;
  font-style: normal;

  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.02em;

  color: #0D0D0D;

  text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -webkit-tap-highlight-color: transparent;

  margin: 0;


  @media(max-width: 700px){
    font-size: 12px;
    line-height: 17px;
  }
}

input {
  border-radius: 0;
}

h1,h2,h3,h4,h5{
  font-family: "Karla", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: normal;

  margin: 0;
}

/* Title 1 */
h1{
  font-size: 50px;
  line-height: 50px;
}

/* Title 2 */
h2 {
  font-size: 22px;
  line-height: 60px;
}

/* Heading 1 */
h3 {
  font-size: 30px;
  line-height: 32px;
}

/* Heading 2 */
h4 {
  font-size: 18px;
  line-height: 24px;
}

/* Heading 3 */
h5 {
  font-size: 12px;
  line-height: 14px;
}

i {
  font-style: normal;
}

.cardo,
.cardo > p {
  font-family: "Cardo", "Helvetica Neue", "Lucida Grande", sans-serif;
}

.cardo-italic {
  font-family: "Cardo", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-style: italic;
}

.cardo,
.cardo-italic {
  text-shadow: none;
  text-rendering: unset !important;
  -webkit-font-smoothing: unset !important;
}

.frac {
  font-feature-settings: "frac";
}


.zero {
  font-feature-settings: "zero";
}

/*--------------------------------------------------------------
  ## Media
  --------------------------------------------------------------*/

video,
img {
  width: 100%;
  margin: 0;

  display: block;
}

/*--------------------------------------------------------------
  ## Buttons
  --------------------------------------------------------------*/

  button{
    background-color: transparent;
    appearance:none;
    border:0;

    padding:0;
    margin:0;

    cursor:pointer;
    color: #0D0D0D;

    &:focus,
    &:hover{
      outline: none;
    }
  }

/*--------------------------------------------------------------
  ## Links
  --------------------------------------------------------------*/
a {
  color: inherit;
  text-decoration: none;

  position: relative;
}

a:visited {
  color: inherit;
}

a:hover,
a:focus,
a:active {
  color: inherit;
}

a:hover,
a:active,
a:focus {
  outline: 0;
}

/*--------------------------------------------------------------
  ## Text
  --------------------------------------------------------------*/

p {
  margin: 1em 0;
}

ul, ol, li{
  list-style: none;
  padding: 0;
  margin: 0;
}

.uppercase{
  text-transform: uppercase;
}

strong {
  font-weight: 400;
  font-style:normal;
  text-transform: uppercase;
}

/*--------------------------------------------------------------
  ## Box Sizing / Layout
  --------------------------------------------------------------*/

div {
  box-sizing: border-box;
}

/*--------------------------------------------------------------
  ## Image Sizing For Previews
  --------------------------------------------------------------*/

.gatsby-image-wrapper-constrained{
  width: 100%;
  height: 100%;
}

/*--------------------------------------------------------------
  ## React Modal
--------------------------------------------------------------*/
.ReactModalPortal{
  position: fixed;

  top:0;
  left:0;
  right:0;
  bottom:0;

  z-index: 100;
}

.ReactModalPortal-mailing-list {
  position: fixed;

  top:0;
  left:0;
  right:0;
  bottom:0;

  z-index: 10000;
  cursor: pointer;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 250ms ease;
}

.ReactModalPortal-mailing-list .ReactModal__Overlay {
  // background-color: #f5eadf !important;
  // background-color: transparent;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.ReactModal__Content{
  width: 100%;
  height: 100%;

  inset: unset !important;
  background: none !important;
  border-radius: 0 !important;
  padding: 0 !important;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  &:focus,
  &:focus-within,
  &:target,
  &:focus-visible{
    outline: 0;
  }
}

/*--------------------------------------------------------------
  ## Terms Feed
--------------------------------------------------------------*/

.termsfeed-pc1-notice-banner  {
  font-family: "Karla","Helvetica Neue","Lucida Grande",sans-serif !important;

  & .termsfeed-pc1-notice-banner-overlay{
    display: none !important;
  }

  & .termsfeed-pc1-notice-banner-layout{
    max-width: 440px !important;
    top: unset !important;

    left: 20px !important;
    bottom: 20px !important;

    @media(max-width: 500px){
      left: 0 !important;
      bottom: 0 !important;
    }
  }

  & .termsfeed-pc1-notice-banner-sitedetails{
    font-family: "Karla","Helvetica Neue","Lucida Grande",sans-serif !important;

    display: none;
  }

  & .termsfeed-pc1-notice-banner-header{
    display: none !important;
  }

  & .termsfeed-pc1-notice-banner-content-weusecookies-title{
    font-family: "Karla","Helvetica Neue","Lucida Grande",sans-serif !important;
    font-size: 15px !important;
    line-height: 23px !important;
    font-weight: 500 !important;
    text-transform: uppercase !important;
    letter-spacing: 0.02em !important;

    margin: 0 0 5px 0 !important;
  }

  & .termsfeed-pc1-notice-banner-content-weusecookies-text-link,
  & #termsfeed_privacy_consent_notice_banner_open_privacy_preferences{
    text-decoration-thickness: from-font !important;
    text-underline-offset: 2px !important;
  }

  & .termsfeed-pc1-notice-banner-content-weusecookies-text,
  & .termsfeed-pc1-notice-banner-content-youcanchange-text{
    font-family: "Karla","Helvetica Neue","Lucida Grande",sans-serif !important;
    font-size: 12px;
    line-height: 16px;
  }

  & .termsfeed-pc1-notice-banner-dnt-text {
    font-size: 9px !important;
    letter-spacing: 0.1em !important;
  }

  & .termsfeed-pc1-notice-banner-footer{
    padding: 0 !important;
  }

  & .termsfeed-pc1-notice-banner-buttons{
    font-family: "Karla","Helvetica Neue","Lucida Grande",sans-serif !important;

    & button {
      font-size: 13px !important;
      letter-spacing: 0.05em !important;
      text-transform:uppercase !important;
      font-family: "Karla","Helvetica Neue","Lucida Grande",sans-serif !important;
      font-weight: 500 !important;


      @media (max-width: 768px) {
        font-size: 11px !important;
        letter-spacing: 0.1em !important;
      }
    }
  }

  & .custom-toggle-label-property-name{
    font-family: "Karla","Helvetica Neue","Lucida Grande",sans-serif !important;
    font-size: 12px;
    line-height: 16px;
    letter-spacing:0 !important;
  }
}

`;

export default GlobalStyle;
