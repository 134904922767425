import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";
import { useWindowSize } from "react-use";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Context
import { PageSetup } from "../components/context/page-setup";

// Seo
import { PageSeo } from "../components/seo/page-seo";

const Page = styled.div`
  margin: 150px 0 150px 0;
  padding: 0 20px;

  min-height: calc(${(props) => props.height}px - 50px - 40px - 150px);

  & .text-container {
    max-width: 700px;

    & a {
      text-decoration: underline;
      text-underline-offset: 1px;
      text-decoration-thickness: from-font;
    }

    & p,
    & ul,
    & ol {
      font-size: 15px;
      line-height: 23px;

      font-family: "Cardo", "Helvetica Neue", "Lucida Grande", sans-serif;
      text-shadow: none;
      text-rendering: unset !important;
      -webkit-font-smoothing: unset !important;

      & strong {
        // display: block;
        // margin: 0 0 2em 0;

        font-size: 15px;
        line-height: 23px;
        font-weight: 500;

        font-family: "Karla", "Helvetica Neue", "Lucida Grande", sans-serif;

        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
        text-rendering: optimizeLegibility !important;
        -webkit-font-smoothing: antialiased !important;
      }

      &:empty {
        display: inline-block;
        margin: 1em 0 0 0;
      }
    }

    & ul {
      list-style: disc;
      padding: 0 0 0 20px;

      & li {
        list-style: disc;
      }
    }

    & ol {
      list-style: numeric;
      padding: 0 0 0 60px;

      & li {
        list-style: numeric;
      }
    }
  }
`;

const TextPage = ({ data }) => {
  const { width, height } = useWindowSize();

  const [pageSetup, setPageSetup] = useContext(PageSetup);

  useEffect(() => {
    setPageSetup({
      pageColor: `#fff`,
      pageType: `text-page`,
    });
  }, []);

  return (
    <>
      <PageSeo
        seoTitle={`${data.prismicTextPage.data.title.text} | SHANNON BOND`}
        seoText={null}
        seoImage={null}
      />
      <Page height={height}>
        <div className="text-container">
          <PrismicRichText field={data.prismicTextPage.data.text.richText} />
        </div>
      </Page>
    </>
  );
};

export default withPrismicPreview(TextPage);

export const query = graphql`
  query TextPage($uid: String) {
    prismicTextPage(uid: { eq: $uid }) {
      data {
        title {
          text
        }
        text {
          richText
        }
      }
    }
  }
`;
