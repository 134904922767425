import React from "react";
import ReactPlayer from "react-player";

// Components
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";

export const Video = ({ url, width, height }) => {
  return (
    <div className="video-container">
      <AspectRatioImageContainer image={null} padding={(height / width) * 100}>
        <ReactPlayer
          url={url}
          width="100%"
          height="100%"
          config={{
            youtube: {
              playerVars: { showinfo: 0, modestbranding: 1, controls: 0 },
            },
          }}
        />
      </AspectRatioImageContainer>
    </div>
  );
};

//https://github.com/cookpete/react-player/issues/321
export const Audio = ({ url, width, height }) => {
  return (
    <div className="audio-container">
      <ReactPlayer
        url={`${url}?color=#ffffff&sharing=false&show_artwork=false&show_playcount=false&show_user=false&buying=false`}
        width="100%"
        height="100%"
      />
    </div>
  );
};
