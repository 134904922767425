import React, { useState, useEffect } from "react";
import { Location } from "@reach/router";
import styled from "styled-components";
import { Normalize } from "styled-normalize";

// Get Urls correct
import { Link } from "gatsby";
import { PrismicProvider } from "@prismicio/react";
import { linkResolver } from "../link-resolver/linkResolver";

// Styles
import GlobalStyles from "../styles/globalStyles";

// Components
import { App } from "./app";
import { Defaults } from "./defaults";

// Context
import { PageSetup } from "../context/page-setup";

const Container = styled.div`
  position: relative;

  & header {
    transition: 300ms background-color ease;
  }

  &.page-type-cart,
  &.page-type-collection,
  &.page-type-page,
  &.page-type-product,
  &.page-type-text-page {
    & header {
      background-color: #fff;

      & .top-menu-navigation,
      & .single-dropdown-menu-container {
        background-color: #fff;
      }
    }
  }

  &.page-type-home {
    background-color: transparent;

    &.active {
      & header {
        background-color: #fff;

        & .top-menu-navigation,
        & .single-dropdown-menu-container {
          background-color: #fff;
        }
      }
    }
  }
`;

const Layout = ({ children }) => {
  const [pageSetup, setPageSetup] = useState({
    pageTitle: ``,
    pageColor: `#fff`,
    pageType: ``,
  });

  useEffect(() => {
    console.log(
      "%cDevelopment: Kieran Startup: www.kieranstartup.co.uk",
      "color:red; font-family:Helvetica Neue;  font-size: 20px"
    );
  }, []);

  return (
    <>
      <PageSetup.Provider value={[pageSetup, setPageSetup]}>
        <Location>
          {({ location }) => (
            <PrismicProvider
              linkResolver={linkResolver}
              internalLinkComponent={({ href, ...props }) => (
                <Link to={href} {...props} />
              )}
            >
              <Container className={`page-type-${pageSetup.pageType}`}>
                <Normalize />
                <Defaults />
                <GlobalStyles />
                <App
                  location={location}
                  children={children}
                  pageSetup={pageSetup}
                  setPageSetup={setPageSetup}
                />
              </Container>
            </PrismicProvider>
          )}
        </Location>
      </PageSetup.Provider>
    </>
  );
};

export default Layout;
