import React, { useState, useRef, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import styled from "styled-components";
import { useClickAway, useLockBodyScroll, useToggle } from "react-use";

// Icons
import { CloseIcon } from "../icons/close-icon";
import { DownloadIcon } from "../icons/arrows";

const SizeGuideContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;

  z-index: 300;

  transition: 300ms transform ease;

  background-color: #fcfcfc;

  width: 500px;
  padding: 25px;

  @media (max-width: 600px) {
    width: 100%;
    padding: 10px;
  }

  &.visible {
    transform: translateX(0);
  }

  &.hidden {
    transform: translateX(500px);

    @media (max-width: 600px) {
      transform: translateX(100%);
    }
  }

  & .toggle-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    margin: 0 0 25px 0;

    & .buttons-container {
      & button {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.02em;
        text-transform: uppercase;

        margin: 0 25px 0 0;

        &.current {
          text-decoration: underline;
          text-underline-offset: 2px;
        }
      }
    }

    & .close-size-guide-container {
      & button {
        padding: 5px;

        & svg {
          width: 10px;
          height: 10px;
        }
      }
    }
  }

  & .grid {
    display: grid;

    &.grid-7 {
      grid-template-columns: repeat(7, 1fr);
    }

    &.grid-3 {
      grid-template-columns: repeat(3, 1fr);
    }

    & .column {
      border-right: 1px solid #bcc5c5;

      &:last-of-type {
        border: 0;
      }
    }

    & ol {
      padding: 0 10px;

      text-align: center;

      @media (max-width: 600px) {
        padding: 0 5px;
      }
    }
  }

  & .header {
    & li {
      font-size: 13px;
      line-height: 13px;
      letter-spacing: 0.05em;
      font-weight: 500;

      padding: 0 10px 5px 10px;
      text-align: center;

      border-right: 1px solid #bcc5c5;

      &:last-of-type {
        border: 0;
      }

      @media (max-width: 600px) {
        font-size: 11px;

        padding: 0 5px 5px 5px;
      }
    }
  }

  & .row-container {
    & ol {
      & li {
        font-size: 14px;
        line-height: 24px;

        @media (max-width: 600px) {
          font-size: 11px;
        }
      }
    }
  }

  & .size-guide-download-container {
    & p {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.02em;
    }
    & a {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: #0d0d0d;
    }

    @media (max-width: 600px) {
      & p,
      & a {
        font-size: 12px;
        line-height: 17px;
      }
    }
  }

  & .table-container {
    border: 1px solid #bcc5c5;
    padding: 15px 0;
    margin: 20px 0 0 0;

    & ol {
      & li {
        font-feature-settings: "tnum";
      }
    }
  }
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 200;
  transition: 300ms opacity ease;

  background-color: #7a7878;
  cursor: pointer;

  &.visible {
    opacity: 0.2;
    visibility: visible;
  }

  &.hidden {
    opacity: 0;
    visibility: hidden;
    user-select: none;
  }
`;

export const SizeGuide = ({ isSizeGuideOpen, setIsSizeGuideOpen }) => {
  const [openSizeGuide, setOpenSizeGuide] = useState(`rings`);
  const [locked, toggleLocked] = useToggle(false);

  useLockBodyScroll(locked);

  useEffect(() => {
    toggleLocked(isSizeGuideOpen);
  }, [isSizeGuideOpen]);

  const ref = useRef(null);
  useClickAway(ref, () => {
    setIsSizeGuideOpen(false);
    toggleLocked(false);
  });

  const data = useStaticQuery(graphql`
    {
      prismicSizeGuide {
        data {
          rings_size_guide {
            url
          }
          bracelet_size_guide {
            url
          }
          rings_text {
            richText
          }
          bracelet_text {
            richText
          }
        }
      }
    }
  `);

  return (
    <>
      <SizeGuideContainer
        className={isSizeGuideOpen ? `visible` : `hidden`}
        ref={ref}
      >
        <div className="toggle-section">
          <div className="buttons-container">
            <button
              onClick={() => setOpenSizeGuide(`rings`)}
              className={openSizeGuide === `rings` ? `current` : ``}
            >
              Rings
            </button>
            <button
              onClick={() => setOpenSizeGuide(`bracelets`)}
              className={openSizeGuide === `bracelets` ? `current` : ``}
            >
              Bracelets
            </button>
          </div>

          <div className="close-size-guide-container">
            <button onClick={() => setIsSizeGuideOpen(false)}>
              <CloseIcon />
            </button>
          </div>
        </div>

        <div className="size-guide-download-container">
          <div className="text-container cardo">
            {openSizeGuide === `bracelets` ? (
              <>
                <PrismicRichText
                  field={data.prismicSizeGuide.data.bracelet_text.richText}
                />
                <a
                  href={data.prismicSizeGuide.data.bracelet_size_guide.url}
                  download
                  target="_blank"
                >
                  <DownloadIcon /> Print Size Guide
                </a>
              </>
            ) : (
              <>
                <PrismicRichText
                  field={data.prismicSizeGuide.data.rings_text.richText}
                />
                <a
                  href={data.prismicSizeGuide.data.rings_size_guide.url}
                  download
                  target="_blank"
                >
                  <DownloadIcon /> Print Size Guide
                </a>
              </>
            )}
          </div>
        </div>

        {openSizeGuide === `rings` ? (
          <div className="table-container">
            <div className="header-container">
              <ol className="header grid grid-7">
                <li className="row">US</li>
                <li className="row">
                  <span className="zero">ø</span>MM
                </li>
                <li className="row">CIRC</li>
                <li className="row">EUR</li>
                <li className="row">PT</li>
                <li className="row">UK AUS</li>
                <li className="row">JP</li>
              </ol>
            </div>

            <div className="row-container grid grid-7">
              <ol className="column column-1" id="us">
                <li className="row">5</li>
                <li className="row">
                  5 <span className="frac">1/4</span>
                </li>
                <li className="row">
                  5 <span className="frac">1/2</span>
                </li>
                <li className="row">
                  5 <span className="frac">3/4</span>
                </li>
                <li className="row">6</li>
                <li className="row">
                  6 <span className="frac">1/4</span>
                </li>
                <li className="row">
                  6 <span className="frac">1/2</span>
                </li>
                <li className="row">
                  6 <span className="frac">3/4</span>
                </li>
                <li className="row">7</li>
                <li className="row">
                  7 <span className="frac">1/4</span>
                </li>
                <li className="row">
                  7 <span className="frac">1/2</span>
                </li>
                <li className="row">
                  7 <span className="frac">3/4</span>
                </li>
                <li className="row">8</li>
              </ol>

              <ol className="column column-2" id="mm">
                <li className="row">15.7</li>
                <li className="row">15.9</li>
                <li className="row">16.1</li>
                <li className="row">16.3</li>
                <li className="row">16.5</li>
                <li className="row">16.7</li>
                <li className="row">16.9</li>
                <li className="row">17.1</li>
                <li className="row">17.3</li>
                <li className="row">17.5</li>
                <li className="row">17.7</li>
                <li className="row">17.9</li>
                <li className="row">18.1</li>
              </ol>

              <ol className="column column-3" id="circ">
                <li className="row">49.3</li>
                <li className="row">50</li>
                <li className="row">50.6</li>
                <li className="row">51.2</li>
                <li className="row">51.9</li>
                <li className="row">52.5</li>
                <li className="row">53.1</li>
                <li className="row">53.8</li>
                <li className="row">54.4</li>
                <li className="row">55.1</li>
                <li className="row">55.7</li>
                <li className="row">56.3</li>
                <li className="row">57</li>
              </ol>

              <ol className="column column-4" id="eur">
                <li className="row">49</li>
                <li className="row">
                  49 <span className="frac">7/8</span>
                </li>
                <li className="row">
                  50 <span className="frac">3/4</span>
                </li>
                <li className="row">
                  52 <span className="frac">1/8</span>
                </li>
                <li className="row">
                  51 <span className="frac">1/2</span>
                </li>
                <li className="row">
                  52 <span className="frac">1/8</span>
                </li>
                <li className="row">
                  52 <span className="frac">3/4</span>
                </li>
                <li className="row">
                  53 <span className="frac">3/8</span>
                </li>
                <li className="row">54</li>
                <li className="row">
                  54 <span className="frac">5/8</span>
                </li>
                <li className="row">
                  55 <span className="frac">1/4</span>
                </li>
                <li className="row">56</li>
                <li className="row">
                  56 <span className="frac">3/4</span>
                </li>
              </ol>

              <ol className="column column-4" id="pt">
                <li className="row">
                  9 <span className="frac">1/2</span>
                </li>
                <li className="row">10</li>
                <li className="row">
                  10 <span className="frac">7/8</span>
                </li>
                <li className="row">
                  11 <span className="frac">3/4</span>
                </li>
                <li className="row">
                  12 <span className="frac">3/4</span>
                </li>
                <li className="row">
                  13 <span className="frac">3/8</span>
                </li>
                <li className="row">14</li>
                <li className="row">
                  14 <span className="frac">5/8</span>
                </li>
                <li className="row">
                  15 <span className="frac">1/4</span>
                </li>
                <li className="row">
                  15 <span className="frac">7/8</span>
                </li>
                <li className="row">
                  16 <span className="frac">1/2</span>
                </li>
                <li className="row">
                  17 <span className="frac">1/8</span>
                </li>
                <li className="row">
                  17 <span className="frac">3/4</span>
                </li>
              </ol>

              <ol className="column column-4" id="uk-aus">
                <li className="row">
                  J <span className="frac">1/2</span>
                </li>
                <li className="row">K</li>
                <li className="row">L</li>
                <li className="row">
                  L <span className="frac">1/2</span>
                </li>
                <li className="row">M</li>
                <li className="row">
                  M <span className="frac">1/2</span>
                </li>
                <li className="row">N</li>
                <li className="row">
                  N <span className="frac">1/2</span>
                </li>
                <li className="row">O</li>
                <li className="row">
                  O <span className="frac">1/2</span>
                </li>
                <li className="row">P</li>
                <li className="row">
                  P <span className="frac">1/2</span>
                </li>
                <li className="row">Q</li>
              </ol>

              <ol className="column column-4" id="jp">
                <li className="row">9</li>
                <li className="row">
                  9 <span className="frac">3/4</span>
                </li>
                <li className="row">
                  10 <span className="frac">1/2</span>
                </li>
                <li className="row">
                  11 <span className="frac">1/4</span>
                </li>
                <li className="row">12</li>
                <li className="row">
                  12 <span className="frac">1/2</span>
                </li>
                <li className="row">13</li>
                <li className="row">
                  13 <span className="frac">1/2</span>
                </li>
                <li className="row">14</li>
                <li className="row">15</li>
                <li className="row">
                  15 <span className="frac">1/2</span>
                </li>
                <li className="row">16</li>
              </ol>
            </div>
          </div>
        ) : (
          <div className="table-container">
            <div className="header-container">
              <ol className="header grid grid-3">
                <li className="row">SIZE</li>
                <li className="row">
                  <span className="zero">ø</span>MM
                </li>
                <li className="row">CIRC (MM)</li>
              </ol>
            </div>

            <div className="row-container grid grid-3">
              <ol className="column column-1" id="size">
                <li className="row">SM</li>
                <li className="row">ML</li>
              </ol>

              <ol className="column column-2" id="mm">
                <li className="row">64</li>
                <li className="row">67</li>
              </ol>

              <ol className="column column-3" id="circ">
                <li className="row">201</li>
                <li className="row">210</li>
              </ol>
            </div>
          </div>
        )}
      </SizeGuideContainer>

      <Background className={isSizeGuideOpen ? `visible` : `hidden`} />
    </>
  );
};
