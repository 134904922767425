import React from "react";
import { Helmet } from "react-helmet";

export const Defaults = () => {
  return (
    <Helmet
      title={`SHANNON BOND`}
      htmlAttributes={{ lang: "en" }}
      hrefLang="en"
      meta={[
        {
          name: "viewport",
          content:
            "width=device-width, user-scalable=no, initial-scale=1, maximum-scale=1",
        },
        {
          name: "facebook-domain-verification",
          content: "ac6zy4urboyk229qg813nj2j89d7xd",
        },
      ]}
      link={[
        {
          rel: "preload",
          href: "/fonts/fonts.css",
          as: "style",
        },
        {
          rel: "dns-prefetch",
          href: "https://images.prismic.io/shannon-bond",
        },
        {
          rel: "preconnect",
          href: "https://images.prismic.io/shannon-bond",
        },
      ]}
    >
      {/* Privacy Consent by TermsFeed.com */}
      <script
        type="text/javascript"
        src="https://cdn.termsfeedtag.com/plugins/pc/v1/aa4bef57e57b441b8382bedbb9bc5643/plugin.js"
        async
      />
    </Helmet>
  );
};
