import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

// Components
// import { CurrencyDropdown } from "./currency-dropdown";

// Icons
import { Logo } from "../icons/logo";

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  padding: 0 20px;
  height: 50px;

  z-index: 200;
  line-height: 50px;

  & nav {
    display: grid;
    grid-template-columns: 1fr 80px 1fr;
    align-items: center;
    height: 50px;
  }

  & .logo-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    & a {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
  }

  & ol {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    line-height: 50px;

    &.right-menu {
      justify-content: flex-end;
      align-items: center;
      line-height: 50px;

      & .cart-link {
        position: relative;
        line-height: 50px;

        & a {
          display: block;
          line-height: 50px;
        }
      }
    }

    & li {
      margin: 0 15px 0 0;

      &:last-of-type {
        margin: 0;
      }

      & a,
      & button {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.05em;
      }

      &.single-menu-link {
        & a {
          display: block;
          line-height: 50px;
        }
      }

      &.dropdown-menu {
        position: relative;

        & button {
          display: block;
          line-height: 50px;
        }

        & .single-dropdown-menu-container {
          display: block;
          position: absolute;
          line-height: 1;
          padding: 0 0 15px 0;
          min-width: 180px;
        }
      }
    }
  }

  & .alert {
    margin: 0 20px 0 0;

    @media (max-width: 1100px) {
      display: none;
    }

    & p {
      font-size: 12px;
      letter-spacing: 0.05em;
      margin: 0;
    }
  }
`;

const BagMarker = styled.span`
  position: absolute;
  top: 16px;
  right: -3px;

  width: 4px;
  height: 4px;

  opacity: ${(props) => props.opacity};
  transition: 250ms opacity ease;

  background: #000;
  display: block;
  border-radius: 100%;
`;

export const DesktopMenu = ({
  location,
  quantity,
  activeCurrency,
  setActiveCurrency,
  data,
}) => {
  const [activeDropdownMenu, setActiveDropdownMenu] = useState(null);

  const menu = data.prismicMenus.data.body.map((content, index) => {
    if (content.slice_type === `page_link`) {
      return (
        <li
          key={`single_menu_item_${index}_${content.id}`}
          className="single-menu-link uppercase"
        >
          <Link to={content.primary.page_link.url}>
            {content.primary.page_title}
          </Link>
        </li>
      );
    }

    if (content.slice_type === `dropdown_menu`) {
      const links = content.items
        .filter((link) => link.collection.document !== null)
        .map((link, index) => (
          <li
            key={`single_link_${index}_${link.collection.document.id}`}
            className="uppercase"
          >
            <Link to={link.collection.document.url}>
              {link.collection.document.data.title.text}
            </Link>
          </li>
        ));

      return (
        <li
          key={`single_menu_item_${index}_${content.id}`}
          className="dropdown-menu"
        >
          <button
            className="uppercase"
            onMouseEnter={() => setActiveDropdownMenu(content.id)}
          >
            {content.primary.dropdown_menu_title}
          </button>

          {activeDropdownMenu === content.id && (
            <ol
              className="single-dropdown-menu-container"
              onMouseLeave={() => setActiveDropdownMenu(null)}
            >
              {links}
            </ol>
          )}
        </li>
      );
    }
  });

  return (
    <HeaderWrapper>
      <nav>
        <ol onMouseLeave={() => setActiveDropdownMenu(null)}>{menu}</ol>

        <div className="logo-container">
          <Link to={`/`}>
            <Logo />
          </Link>
        </div>

        <ol className="right-menu">
          <li className="cart-link">
            <Link to={`/bag/`}>BAG</Link>
            <BagMarker opacity={quantity >= 1 ? 1 : 0} />
          </li>
        </ol>
      </nav>
    </HeaderWrapper>
  );
};
