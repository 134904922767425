import React from "react";

export const MenuIcon = () => (
  <svg
    width="14"
    height="6"
    viewBox="0 0 14 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line y1="0.5" x2="14" y2="0.5" stroke="#0d0d0d" />
    <line y1="5.5" x2="14" y2="5.5" stroke="#0d0d0d" />
  </svg>
);
