import React from "react";

export const ProductPrice = ({ price, salePrice }) => {
  if (salePrice === null) {
    return (
      <>
        {new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: `USD`,
        }).format(price)}
      </>
    );
  } else {
    return (
      <span>
        {new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: `USD`,
        }).format(price)}

        <s className="sale-price">
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: `USD`,
          }).format(salePrice)}
        </s>
      </span>
    );
  }
};
