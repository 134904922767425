import React from "react";

export const CareGuideIcon = () => (
  <svg
    width="7"
    height="7"
    viewBox="0 0 7 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.38721 0.268066L1.93086 0.268067L1.93086 1.16839L5.48689 1.16839L5.48689 4.72441L6.38721 4.72441L6.38721 0.268066Z"
      fill="#0D0D0D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.0253906 6.10501L5.22446 0.905937L5.74965 1.43112L0.550577 6.63019L0.0253906 6.10501Z"
      fill="#0D0D0D"
    />
  </svg>
);

export const SizeGuideIcon = () => (
  <svg
    width="8"
    height="7"
    viewBox="0 0 8 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.17142 0L2.71507 3.18731e-07L2.71507 0.900319L6.2711 0.900319L6.2711 4.45635L7.17142 4.45635L7.17142 0Z"
      fill="#0D0D0D"
    />
    <path
      d="M0.171875 7L4.62822 7V6.09968L1.07219 6.09968L1.07219 2.54365L0.171875 2.54365L0.171875 7Z"
      fill="#0D0D0D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.809601 5.83694L6.00867 0.637871L6.53386 1.16306L1.33479 6.36213L0.809601 5.83694Z"
      fill="#0D0D0D"
    />
  </svg>
);

export const DownloadIcon = () => (
  <svg
    width="7"
    height="9"
    viewBox="0 0 7 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.20635 8.9477L6.35746 5.79659L5.72084 5.15997L3.20635 7.67446L0.691858 5.15997L0.0552378 5.79659L3.20635 8.9477Z"
      fill="#0D0D0D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.57749 0.321878L3.57749 7.67448L2.83477 7.67448L2.83477 0.321878L3.57749 0.321878Z"
      fill="#0D0D0D"
    />
  </svg>
);

export const CurrencyDropdownArrow = () => (
  <svg
    width="7"
    height="5"
    viewBox="0 0 7 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.79289 3.5L3.5 4.20711L4.20711 3.5L7 0.707106L6.29289 0L3.5 2.79289L0.707107 0L0 0.707107L2.79289 3.5Z"
      fill="#0D0D0D"
    />
  </svg>
);
