export const getMetafield = (product, fieldKey) => {
  const metafields = product.metafields.length >= 1 ? product.metafields : null;

  const metafield =
    metafields !== null
      ? metafields
          .filter((field) => field.key === fieldKey)
          .map((field) => field.value)
      : null;

  if (metafield !== null && metafield.length >= 1) {
    return metafield[0];
  } else {
    return null;
  }
};
